import React from "react"

import Layout from "../components/layout"
import Rule from '../components/rule';

const NotFoundPage = () => (
  <Layout>
    <Rule />
    <div className="o-grid o-grid--small-full o-grid--medium-fit o-grid--large-full">
      <div className="o-grid__cell o-grid__cell--width-100 o-grid__cell--width-100@large" itemScope={true}>
        <h2>404 Ei leitud</h2>
      </div>
    </div>
    <Rule last />
  </Layout>
)

export default NotFoundPage
